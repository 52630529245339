/**
 *
 * @param id Id of the search field
 * @param results_id - Result output id
 * @param params
 */
function pvc_search(id, results_id, params) {

	var $ = jQuery, searchEl = $(id), resultsEl, value;
	var req = null, rq = null;
	var curtime = new Date();
	var url = params.baseURI + 'index.php?tmpl=component', data;

	searchEl.attr("autocomplete", "off");

	// Close it if clicked or touch else where.
	$(window).on('click', function (e) {
		$(results_id).empty().removeClass('pvc_results').css('visibility', 'hidden');
	});


	searchEl.on('keyup', function (e) {

		if (e.keyCode == 13) {
			return;
		}

		resultsEl = $(results_id);
		resultsEl.removeClass('pvc_results');
		resultsEl.css('visibility', 'hidden');

		value = searchEl.val();

		if (value == '') {
			$(results_id).empty().removeClass('ps_results').css('visibility', 'hidden');
		} else if (value.length > 3) {

			data = {
				'option': params.option || 'com_publivateconversations',
				'view': params.view || 'dedup',
				'format': 'json',
				'rlang': params.lang,
				'layout': params.layout || 'autocomplete',
				'Itemid': params.Itemid,
				//			'searchphrase' : _optPhrase, //any,all,exact
				'search': value,
				'idea_layout': params.idea_layout || null,
				'r': curtime.getTime(),
				challenge_id : $('#challenge_id').val(),
			};

			req = $.get(url, data,
				function (results) {
					// Add Results main function
					var closeBtn, div, res_header, searchname, query = [], viewAllHref;

					if (results.status == 2) {
						return;
					} // No Results;

					resultsEl.empty().removeClass('pvc_results').css('visibility', 'visible');

					resultsEl.addClass('pvc_results');


					// Create close button link.
					closeBtn = $('<button />', {
						'class': 'pvc_close_link',
						'id': results_id + '_psv_close_link'
					}).prop('href', '#').html($('<span class="">x</span><span class="sr-only">'+params.txtClose+'</span>'));

					closeBtn.on('click', function (e) {
						e.stopPropagation();
						searchEl.focus();
						resultsEl.empty().removeClass('pvc_results').css('visibility', 'hidden');
					});


					resultsEl.append(closeBtn);
					// res_header = ;
					// Add header.
					resultsEl.append(jQuery('<div />', {'class': 'pvc_header'}).text(params.txtHeader));


					// var data = $('<div />', {'class': 'pvc_results_inner'}).html(results.content);
					resultsEl.append($('<div />', {'class': 'pvc_results_inner'}).html(results.content));
					// data.inject(resultsEl);

					div = $('<div />', {'class': 'pvc_view_all'});
					resultsEl.append(div);

					if (params.showViewAllLink == 1) {
						if (params.viewAllLink) {
							searchname = (params.searchname || 'search');
							viewAllHref = params.viewAllLink;
							viewAllHref += '&' + searchname + '=' + value;
						} else {
							query.push('option' + '=' + (params.option || 'com_publivateconversations'));
							query.push('view' + '=' + (params.view || 'dedup'));
							query.push('layout' + '=' + (params.layout || 'autocomplete'));
							query.push('format' + '=' + 'raw');
							query.push('lang' + '=' + params.lang);
							query.push('Itemid' + '=' + params.Itemid);
							query.push((params.searchname || 'search') + '=' + value);
							viewAllHref = 'index.php?' + query.join('&');
						}

						div.append($('<a />', {'href': viewAllHref}).text(params.txtViewAll));
					}

					jQuery(results_id + ' a').last().blur(function (e) {
						$(results_id).empty().removeClass('pvc_results').css('visibility', 'hidden');
						this.tabindex = null;
					});

				}
			);
		}
	});

	searchEl.on('keydown', function () {
		if (req && req.readyState != 4) {
			req.abort();
		}
	});
}
/*************************************************
 Star Rating System
 First Version: 21 November, 2006
 Author: Ritesh Agrawal
 Inspriation: Will Stuckey's star rating system (http://sandbox.wilstuckey.com/jquery-ratings/)
 Demonstration: http://php.scripts.psu.edu/rja171/widgets/rating.php
 Usage: $('#rating').rating('www.url.to.post.com', {maxvalue:5, curvalue:0});
 
 arguments
 url : required -- post changes to
 options
 maxvalue: number of stars
 curvalue: number of selected stars
 setRating: function to set the format rating.
 
 ************************************************/

jQuery.fn.rating = function (url, options) {
	var container = jQuery(this), div, title, stars, cancel;
	var settings = {
		url: url, // post changes to
		maxvalue: 5, // max number of stars
		curvalue: 0, // number of selected stars
		titles: [],
		formId: null,
		post: false,
		cancel: false,
		other_query_params: '',
		setRating: function () {
		},
		onclick: function () {
		},
		onSuccess: function () {
		} // called after rating is posted.
	};

	// debugger;

	if (options) {
		jQuery.extend(settings, options);
	}

	// jQuery.extend(settings, {cancel: (settings.maxvalue > 1) ? true : false});

	settings.url = (settings.formId) ? jQuery(settings.formId).get('action') : settings.url;

	jQuery.extend(container, {
		averageRating: settings.curvalue,
		url: settings.url
	});

	for (var i = 0; i <= settings.maxvalue; i++) {
		var size = i;
		if (i == 0) {
			if (settings.cancel == true) {
				div = '<div class="cancel"><a href="#0" title="Cancel Rating">Cancel Rating</a></div>';
				container.append(div);
			}
		} else {
			title = (settings.titles.length > i) ? settings.titles[i] : '';
			div = '<div id="rating-' + i + '" class="star"><a data-vote="'+ i + '" href="' + i + '" title="' + i + '/' + settings.maxvalue + '"><span class="pv-icon-rate-outline pv-icon text-black"></span></a></div>';
			container.append(div);
		}
	}

	stars = jQuery(container).children('.star');
	cancel = jQuery(container).children('.cancel');

	stars
			.mouseover(function () {
				event.drain();
				event.fill(this);
			})
			.mouseout(function () {
				event.drain();
				event.reset();
			})
			.focus(function () {
				event.drain();
				event.fill(this);
			})
			.blur(function () {
				event.drain();
				event.reset();
			});

	stars.find('a')
			.focus(function () {
				jQuery(this).closest('.star').focus();
			})
			.blur(function () {
				jQuery(this).closest('.star').blur();
			});

	function action(e) {
		var data;

		if (settings.onclick) {
			settings.onclick(e);
		}

		if (settings.maxvalue > 1) {
			settings.curvalue = stars.index(this) + 1;
			var input_id = settings.input_id || null;
			settings.setRating(settings.curvalue, input_id);
			if (settings.post) {
				data = (settings.formId) ? jQuery(settings.formId).serialize() : {"rating": jQuery(this).children('a')[0].href.split('#')[1]};
				jQuery.post(container.url, data + settings.other_query_params, settings.onSuccess, 'json');
			}
			return false;
		} else if (settings.maxvalue == 1) { // Cancel should be enable, like rating; current not used.

			settings.curvalue = (settings.curvalue == 0) ? 1 : 0;
			$(this).toggleClass('on');

			if (settings.post) {
				data = (settings.formId) ? jQuery(settings.formId).serialize() : {"rating": jQuery(this).children('a')[0].href.split('#')[1]};
				jQuery.post(container.url, data + settings.other_query_params, settings.onSuccess, 'json');
			}

			return false;
		}


		return true;
	}

	stars.on('click', action);
	// cancel button events
	if (cancel) {
		cancel
				.mouseover(function () {
					event.drain();
					jQuery(this).addClass('on');
				})
				.mouseout(function () {
					event.reset();
					jQuery(this).removeClass('on');
				})
				.focus(function () {
					event.drain();
					jQuery(this).addClass('on');
				})
				.blur(function () {
					event.reset();
					jQuery(this).removeClass('on');
				});

		// click events.
		cancel.click(function () {
			event.drain();
			settings.curvalue = 0;
			try {
				settings.setRating(settings.curvalue, settings.input_id || null);
				if (settings.post) {
					var data = (settings.formId) ? jQuery(settings.formId).serialize() : {"rating": jQuery(this).children('a')[0].href.split('#')[1]};
					jQuery.post(container.url, data + settings.other_query_params, settings.onSuccess, 'json');
				}
			} catch (e) {
			}

			return false;
		});
	}

	var event = {
		fill: function (el) { // fill to the current mouse position.
			var index = stars.index(el) + 1;
			stars.children('a').end().slice(0, index).addClass('hover').end();
		},
		drain: function () { // drain all the stars.
			stars.filter('.on').removeClass('on').end().filter('.hover').removeClass('hover').end();
		},
		reset: function () { // Reset the stars to the default index.
			stars.slice(0, settings.curvalue).addClass('on').end();
		}
	};

	event.reset();

	return (this);
};

